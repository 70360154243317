/* eslint-disable @typescript-eslint/no-explicit-any */
import { OrganizerDTO } from '~/dto/organizer/OrganizerDTO'
import { BuyerOrganizerDTO } from '~/dto/BuyerOrganizerDTO'
import { OrderItemExtend } from '~/dto/order/OrderItemDTO'
import { OrderInvoiceExtend } from '~/dto/order/OrderInvoiceDTO';

export class OrderDTO {

  public id = 0;
  public userId = 0;
  public buyerOrganizerId = 0;
  public orgaId = 0;
  public type = '';
  public signatureId = '';
  public cancelledStatus = '';
  public archiveId = 0;
  public madeByOrga = 0; // todo : turn to bool
  public date = '';
  public dateUpdated = '';
  public isNewOrder = 0;
  public isSepaLitigation = false;
  public qrCode = '';

  public classOrganizer!: OrganizerDTO;
  public classBuyerSchool!: BuyerOrganizerDTO;

  public metaComment = '';

  // AMOUNT
  public onlineCollected =0
  public onlineToCollect=0
  public onliveCollected=0
  public onliveToCollect=0
  public isItOneUnpaid=0

  // TO DELETE ?
  public totalAmount = 0;
  public totalOnline = 0;

  // SERIALISATION
  public serializedHeader:any = "";
  public serializedParticipants:any  = "";
  public serializedConditions:any ="";
  public serializedEpass:any ="";
  public serializedDiscount:any ="";

  // CANCELLED STATUS
  readonly STATUT_CANCELLED_PARTIAL = 'partial';
  readonly STATUT_CANCELLED_TOTAL = 'total';
  readonly STATUT_NOT_CANCELLED = 'none';

  public STATUT_CANCELLED = [
    { name: 'Annulé partial', value: this.STATUT_CANCELLED_PARTIAL },
    { name: 'Annulé total', value: this.STATUT_CANCELLED_TOTAL },
    { name: 'Non', value: this.STATUT_NOT_CANCELLED }
  ];

  constructor(init?: Partial<OrderDTO>) {
    if (init === undefined) return
    Object.assign(this, init)
  }

  get totalAmountFront(): string {
    return this.totalAmount?.toFixed(2).replace('.', ',') + ' €'
  }

  get totalOnlineFront(): string {
    return this.totalOnline?.toFixed(2).replace('.', ',') + ' €'
  }
}

export class OrderExtend extends OrderDTO {
  [x: string]: any;
  public organizer!: OrganizerDTO;
  public buyerOrganizer: BuyerOrganizerDTO = new BuyerOrganizerDTO();
  public orderItems!: OrderItemExtend[];
  public orderInvoices!: OrderInvoiceExtend[];
  public numberInvoice = 0
  public invoiceCreditInformation = ""
  public pdfPictureLink = ""
  order: any;

  constructor(init?: Partial<OrderExtend>) {
    super()
    if (init === undefined) return
    Object.assign(this, init)
  }
  
}
