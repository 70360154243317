import { BuyerGenericDTO } from "./BuyerGenericDTO";
export class BuyerOrganizerDTO extends BuyerGenericDTO {

  public buyerOrganizerId=0
  public buyerMasterId = 0
  public userWpId = 0
  public orgaId = 0
  public archive = ''
 
  constructor(init?: Partial<BuyerOrganizerDTO>) {
    super()
    if (init === undefined) return
    Object.assign(this, init)
  }
}
